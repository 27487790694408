import React, { useState } from 'react';
import axios from 'axios';
import i18n from "i18next";
import { useTranslation } from 'react-i18next';

const EmailSubscriptionForm = ({ screenWidth, position }) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [emailValid, setEmailValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    // const baseURL = 'http://localhost:3001';
    const baseURL = 'https://www.kaioapp.com';

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        validateEmail(e.target.value);
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setEmailValid(re.test(email));
    };

    const handleCTAClick = async (e) => {
        e.preventDefault();
        if (emailValid) {
            setIsLoading(true);
            const formData = { email: email };
            const locale = i18n.language || 'en';

            try {
                const response = await axios.post(`${baseURL}/api/email.ts`, {
                    email: formData.email,
                    locale: locale
                }, {
                    withCredentials: true
                });

                setIsLoading(false);
                setIsSuccess(true);
                console.log("Email sent successfully", response.data);

            } catch (error) {
                setIsLoading(false);
                if (error.response) {
                    console.error("Error response:", error.response.data);
                    alert(`Failed to send email: ${error.response.data.error}`);
                } else if (error.request) {
                    console.error("Error request:", error.request);
                    alert("Failed to send email: No response from server.");
                } else {
                    console.error("Error message:", error.message);
                    alert(`Failed to send email: ${error.message}`);
                }
            };
        } else {
            console.log("Invalid email");
        }
    };

    const styles = {
        emailCoverContainer: {
            display: 'flex',
            flexDirection: screenWidth < 768 ? 'column' : 'row',
            alignItems: 'center',
            width: '100%',
            margin: '0 auto',
        },
        emailContainer: {
            padding: '2rem',
            display: 'flex',
            flexDirection: screenWidth < 768 ? 'column' : 'row',
            alignItems: 'center',
            maxWidth: screenWidth < 768 ? '100%' : '50%',
            margin: '0 auto',
        },
        emailInput: {
            flex: 1,
            marginBottom: screenWidth < 768 ? '2rem' : '0',
            marginRight: screenWidth < 768 ? '0' : '1rem',
        },
        ctaButton: {
            flexShrink: 0,
        },
        spinner: {
            marginLeft: '8px',
            width: '16px',
            height: '16px',
            border: '2px solid rgba(255, 255, 255, 0.3)',
            borderRadius: '50%',
            borderTopColor: '#fff',
            animation: 'spin 1s ease-in-out infinite',
        },
        checkmark: {
            marginLeft: '8px',
            color: 'white',
        },
    };

    return (
        <div style={position === 'cover' ? styles.emailCoverContainer : styles.emailContainer}>
            <div className="control has-icons-left has-icons-right" style={styles.emailInput}>
                <input
                    className={`input is-medium ${emailValid && "is-success"}`}
                    type="email"
                    placeholder={t("cover.enter_email")}
                    value={email}
                    onChange={handleEmailChange}
                />
                <span className="icon is-small is-left">
                    <i className="fas fa-envelope fa-xs"></i>
                </span>
                {email && (
                    <span className="icon is-small is-right">
                        <i className={`fas ${emailValid ? "fa-check" : "fa-exclamation-triangle"} fa-xs`}></i>
                    </span>
                )}
            </div>
            <button
                className={`button is-medium is-rounded bg-button ${emailValid ? "bg-button-valid" : ""}`}
                onClick={handleCTAClick}
                style={emailValid ? { ...styles.ctaButton, backgroundColor: '#9B2C2C', color: 'white' } : styles.ctaButton}
                disabled={!emailValid || isLoading || isSuccess}
            >
                {isLoading && <span className="spinner" style={styles.spinner}></span>}
                {isSuccess && !isLoading && <i className="fas fa-check" style={styles.checkmark}></i>}
                {!isLoading && !isSuccess && t("footer.cta")}
            </button>
        </div>
    );
};

export default EmailSubscriptionForm;
